import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import {
  type Machine,
  type MeasurementMappingAndVariants,
  type ReportTemplateVariable,
  VariableControlType,
  WallMotionVariant,
  renderVariableControlType,
} from '@piccolohealth/echo-common';
import { SelectCheck, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MeasurementMachineTagsList } from '../../../features/dicom/components/mappings/MeasurementMachineTagsList';
import { MeasurementPropertiesTagsList } from '../../../features/dicom/components/mappings/MeasurementPropertyTagsList';
import { useMachinesQuery } from '../../../graphql/hooks/useMachinesQuery';
import { useMeasurementMappingsAndVariantsQuery } from '../../../graphql/hooks/useMeasurementMappingsAndVariantsQuery';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedMultiSelect } from '../../forms/hookform/HookedMultiSelect';
import { HookedSelect } from '../../forms/hookform/HookedSelect';
import { ReportTemplateVariableChoiceForm } from './ReportTemplateVariableChoiceForm';


const CHOICE_CONTROL_TYPES = [
  VariableControlType.Select,
  VariableControlType.Multiselect,
  VariableControlType.Checkbox,
]

const STATIC_CONTROL_TYPES = [
  VariableControlType.Input,
  VariableControlType.Datepicker,
]


const ReportTemplateVariableMappingOption = React.memo(
  (props: { mappingAndVariants: MeasurementMappingAndVariants; machines: Machine[] }) => {
    const { mappingAndVariants, machines } = props;
    const { mapping, variants } = mappingAndVariants;

    const variantMachines = React.useMemo(
      () =>
        P.intersectBy(
          machines,
          variants.map((variant) => variant.machine),
          (machine) => machine.model,
        ),
      [machines, variants],
    );
    const properties = React.useMemo(
      () =>
        P.uniqBy(
          variants.flatMap((variant) => variant.properties),
          (property) => `${property.name} -${property.value}`,
        ),
      [variants],
    );

    return (
      <Stack w='3xl'>
        <HStack align='start' justify='space-between' w='full'>
          <Text fontWeight='semibold'>{mapping.name}</Text>
          <MeasurementMachineTagsList machines={variantMachines} />
        </HStack>
        <Text fontSize='xs' color='secondary'>
          {mapping.site}
        </Text>
        <MeasurementPropertiesTagsList properties={properties} />
      </Stack>
    );
  },
);

export const ReportTemplateVariableTypeControl = () => {
  const { setValue } = useFormContext();
  const type = useWatch({ name: '__typename' }) as ReportTemplateVariable['__typename'];
  const controlType = useWatch({ name: 'controlType' }) as VariableControlType;

  const machinesQuery = useMachinesQuery({});
  const machines = (machinesQuery.data?.dicom.machines ?? []) as Machine[];

  const mappingsAndVariantsQuery = useMeasurementMappingsAndVariantsQuery({
    request: {
      pagination: { limit: 100000 },
    },
  });
  const mappingsAndVariants = (mappingsAndVariantsQuery.data?.dicom.measurementMappingsAndVariants
    .mappingsAndVariants ?? []) as MeasurementMappingAndVariants[];

  const mappingOptions = P.orderBy(
    mappingsAndVariants.map((measurementMappingAndVariants) => {
      return {
        label: `${measurementMappingAndVariants.mapping.name} - ${measurementMappingAndVariants.mapping.id}`,
        value: measurementMappingAndVariants.mapping.id,
        raw: measurementMappingAndVariants,
      };
    }),
    (option) => option.label,
  );

  React.useEffect(() => {
    switch (type) {
      case 'ReportTemplateStaticVariable': {
        if (!STATIC_CONTROL_TYPES.includes(controlType)) {
          setValue('controlType', P.first(STATIC_CONTROL_TYPES));
        }
        break;
      }
      case 'ReportTemplateChoiceVariable': {
        if (!CHOICE_CONTROL_TYPES.includes(controlType)) {
          setValue('controlType', P.first(CHOICE_CONTROL_TYPES));
          setValue('choices', []);
        }
        break;
      }
    }
  }, [type, controlType, setValue]);

  if (machinesQuery.isLoading || mappingsAndVariantsQuery.isLoading) {
    return <Spin />;
  }

  switch (type) {
    case 'ReportTemplateStaticVariable': {
      const staticOptions = STATIC_CONTROL_TYPES.map((type) => ({
        value: type,
        label: renderVariableControlType(type),
        raw: type,
      }));

      return (
        <>
          <HookedFormItem label='Control type' name='controlType'>
            <HookedSelect name='controlType' options={staticOptions} />
          </HookedFormItem>
          <HookedFormItem label='Units' name='units'>
            <HookedInput name='units' />
          </HookedFormItem>
          <HookedFormItem label='Default value' name='defaultValue'>
            <HookedInput name='defaultValue' />
          </HookedFormItem>
          <HookedFormItem label='Mappings' name='mappings'>
            <HookedMultiSelect
              name='mappings'
              placeholder='Choose one or more mappings'
              options={mappingOptions}
              components={{
                Option: (props) => {
                  return (
                    <HStack spacing={2} py={1} fontSize='sm' w='full'>
                      <ReportTemplateVariableMappingOption
                        mappingAndVariants={props.option.raw}
                        machines={machines}
                      />
                      <Spacer />
                      <SelectCheck />
                    </HStack>
                  );
                },
                Value: (props) => {
                  return (
                    <ReportTemplateVariableMappingOption
                      mappingAndVariants={props.option.raw}
                      machines={machines}
                    />
                  );
                },
              }}
            />
          </HookedFormItem>
        </>
      );
    }
    case 'ReportTemplateChoiceVariable': {
      const choiceOptions = CHOICE_CONTROL_TYPES.map((type) => ({
        value: type,
        label: renderVariableControlType(type),
        raw: type,
      }));

      return (
        <>
          <HookedFormItem label='Control type' name='controlType'>
            <HookedSelect name='controlType' options={choiceOptions} />
          </HookedFormItem>
          <HookedFormItem label='Choices' name='choices'>
            <ReportTemplateVariableChoiceForm name='choices' />
          </HookedFormItem>
          <HookedFormItem label='Mappings' name='mappings'>
            <HookedMultiSelect
              name='mappings'
              placeholder='Choose one or more mappings'
              options={mappingOptions}
              components={{
                Option: (props) => {
                  return (
                    <HStack spacing={2} py={1} fontSize='sm' w='full'>
                      <ReportTemplateVariableMappingOption
                        mappingAndVariants={props.option.raw}
                        machines={machines}
                      />
                      <Spacer />
                      <SelectCheck />
                    </HStack>
                  );
                },
                Value: (props) => {
                  return (
                    <ReportTemplateVariableMappingOption
                      mappingAndVariants={props.option.raw}
                      machines={machines}
                    />
                  );
                },
              }}
            />
          </HookedFormItem>
        </>
      );
    }
    case 'ReportTemplateWallMotionVariable': {
      const variantOptions = Object.values(WallMotionVariant).map((variant) => ({
        value: variant,
        label: variant,
        raw: variant,
      }));

      return (
        <>
          <HookedFormItem label='Variant' name='variant'>
            <HookedSelect name='variant' options={variantOptions} />
          </HookedFormItem>
        </>
      );
    }

    default:
      return null;
  }
};
