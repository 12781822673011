import { Box, type ResponsiveValue } from '@chakra-ui/react';
import type {
  ReportTemplateChoiceVariable,
  ReportTemplateVariableChoice,
} from '@piccolohealth/echo-common';
import type { SelectOption } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';
import { ReportTemplateVariableManageChoicesFooter } from '../../../templates/variables/ReportTemplateVariableManageChoicesFooter';
import { HighlightedVariableValue } from './HighlightedVariableValue';
import { HookedMultiSelect } from '../../../forms/hookform/HookedMultiSelect';

interface Props {
  name: string;
  reportTemplateVariable: ReportTemplateChoiceVariable;
  shouldCapitalize: () => boolean;
  isDisabled?: boolean;
  size?: ResponsiveValue<string>;
}

export const ChoiceVariableControl = (props: Props) => {
  const { name, reportTemplateVariable, size, isDisabled, shouldCapitalize } = props;
  const { compiledTemplate } = useCompiledTemplate(`{{${reportTemplateVariable.id}}}`);

  const options: SelectOption<ReportTemplateVariableChoice>[] = reportTemplateVariable.choices.map(
    (choice) => ({
      label: P.upperFirst(choice.value),
      value: choice.value,
      raw: choice,
    }),
  );

  return (
    <Box as='span' whiteSpace='pre' display='inline-block' layerStyle='inlineFocus'>
      <HookedMultiSelect
        name={name}
        variant='unstyled'
        mode="single"
        options={options}
        isDisabled={isDisabled}
        showArrow={false}
        optionVariant='text'
        data-pw={`choiceVariableControl-${reportTemplateVariable.id}`}
        size={size}
        bg='transparent'
        components={{
          Placeholder: () => (
            <HighlightedVariableValue
              value={null}
              reportTemplateVariable={reportTemplateVariable}
              template={compiledTemplate}
              capitalize={shouldCapitalize()}
              size={size}
              showChoices={false}
            />
          ),
          Value: (props) => (
            <HighlightedVariableValue
              value={props.option?.value}
              template={compiledTemplate}
              reportTemplateVariable={reportTemplateVariable}
              capitalize={shouldCapitalize()}
              size={size}
              showChoices={false}
            />
          ),
          Footer: () => (
            <ReportTemplateVariableManageChoicesFooter
              reportTemplateVariable={reportTemplateVariable}
            />
          ),
        }}
      />
    </Box>
  );
};
