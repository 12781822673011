import { string, type BaseSchema, type StringSchema, type ValidationError } from 'yup';
import type { Maybe } from 'yup/lib/types';

export const validateSchema = <A>(schema: BaseSchema<A>, values: A): ValidationError[] => {
  try {
    schema.validateSync(values, { abortEarly: false });
    return [];
  } catch (error) {
    return error.inner;
  }
};

export const enumSchema = <A extends Maybe<string>>(value: Record<string, A>) => {
  return string().oneOf(Object.values(value as any)) as StringSchema<A>;
};
