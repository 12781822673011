import { Divider, Stack, Tag, Text, type TextProps, Tooltip } from '@chakra-ui/react';
import type { ReportTemplateVariable } from '@piccolohealth/echo-common';
import { inflection, P } from '@piccolohealth/util';
import React from 'react';

/**
 * This is a nasty component. The value is passed in as a prop, but it is not
 * displayed. It is just used to determine if we show a placeholder or not.
 */
interface Props extends TextProps {
  reportTemplateVariable: ReportTemplateVariable;
  value?: unknown | null;
  template?: string;
  capitalize: boolean;
  showChoices: boolean;
}

export const HighlightedVariableValue = (props: Props) => {
  const { value, reportTemplateVariable, template, capitalize, showChoices, ...rest } = props;

  const isEmptyString = P.isString(value) && P.isEmptyString(value);
  const isEmptyArray = P.isArray(value) && P.isEmpty(value);
  const showPlaceholder = P.isNil(value) || isEmptyString || isEmptyArray;

  const rendered = P.run(() => {
    if (showPlaceholder) {
      return <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
    }

    if (template && capitalize) {
      return P.capitalize(template);
    }

    return template;
  });

  const label = P.run(() => {
    if (!showChoices) {
      return reportTemplateVariable.label;
    }

    switch (reportTemplateVariable.__typename) {
      case 'ReportTemplateChoiceVariable': {
        return (
          <Stack align='start'>
            <Stack spacing={0}>
              <Text fontWeight='semibold'>{reportTemplateVariable.label}</Text>
              <Text fontSize='xs'>
                {reportTemplateVariable.choices.length}{' '}
                {inflection.inflect('choices', reportTemplateVariable.choices.length)}
              </Text>
            </Stack>

            <Divider />
            {reportTemplateVariable.choices.map((choice) => (
              <Tag key={choice.id} size='sm' colorScheme={choice.default ? 'green' : 'gray'}>
                {choice.value}
              </Tag>
            ))}
          </Stack>
        );
      }
      default: {
        return reportTemplateVariable.label;
      }
    }
  });

  return (
    <Tooltip label={label} p={2} bg='gray.700' color='white' openDelay={500}>
      <Text
        as='span'
        variant='highlighted'
        cursor='pointer'
        zIndex={10}
        userSelect='text'
        {...rest}
      >
        {rendered}
      </Text>
    </Tooltip>
  );
};
