import { Box, type BoxProps, type ButtonProps, Wrap, WrapItem, chakra } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import {
  type MultiSelectOption,
  type OnChangeRequest,
  useMultiSelect,
} from '../hooks/useMultiSelect';
import { FloatingPopover } from './FloatingPopover';
import { ScrollAreaAutosize } from './ScrollArea';
import {
  type OptionVariant,
  SelectButton,
  SelectCheck,
  SelectComponents,
  SelectContainer,
  SelectInput,
  SelectItem,
  TagOption,
  TagValue,
  TextOption,
  TextValue,
} from './Select';

export type { MultiSelectOption, OnChangeRequest };
export { SelectCheck };

export interface MultiSelectComponents<A> {
  Loading?: () => React.ReactElement | null;
  Value?: (props: {
    selectedValues: MultiSelectOption<A>[];
    option: MultiSelectOption<A>;
    onRemove: () => void;
    index: number;
  }) => React.ReactElement | null;
  Placeholder?: (props: { placeholder?: string }) => React.ReactElement | null;
  NoOptions?: () => React.ReactElement | null;
  Option?: (props: { option: MultiSelectOption<A> }) => React.ReactElement | null;
  Header?: () => React.ReactElement | null;
  Footer?: () => React.ReactElement | null;
}

export const MultiSelectComponents = SelectComponents;

const getComponents = <A,>(
  components: Partial<MultiSelectComponents<A>>,
  optionVariant: OptionVariant,
) => {
  return {
    ...SelectComponents,
    Option: optionVariant === 'tag' ? TagOption : TextOption,
    Value: optionVariant === 'tag' ? TagValue : TextValue,
    Footer: () => <></>,
    Header: undefined,
    ...components,
  };
};

export interface MultiSelectProps<A> extends Omit<BoxProps, 'onChange'> {
  options: MultiSelectOption<A>[];
  selectedValues: MultiSelectOption<A>[];
  inputValue?: string;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  optionVariant?: OptionVariant;
  mode?: 'single' | 'multiple';
  placeholder?: string;
  isLoading?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  showArrow?: boolean;
  components?: MultiSelectComponents<A>;
  onChange: (req: OnChangeRequest<A>) => void;
  onInputChange?: (value: string) => void;
  onOpen?: () => void;
}

export const MultiSelect = <A,>(props: MultiSelectProps<A>) => {
  const {
    options,
    selectedValues,
    inputValue,
    placeholder,
    isLoading,
    isCreatable,
    isDisabled,
    components,
    variant = 'selectOutline',
    mode = 'multiple',
    showArrow = true,
    optionVariant = 'text',
    size = 'sm',
    onChange,
    onInputChange,
    onOpen,

    ...rest
  } = props;

  const select = useMultiSelect<A>({
    isCreatable,
    mode,
    options,
    selectedValues,
    inputValue,
    isDisabled,
    onChange,
    onInputChange,
    onOpen,
  });

  const Components = getComponents(components ?? {}, optionVariant);

  return (
    <chakra.div {...select.menuProps()} w='full' {...rest}>
      <FloatingPopover
        open={select.isOpen}
        enabled={!isDisabled}
        placement='bottom-start'
        isRoot={false}
        trigger='focus'
        render={() => (
          <SelectContainer>
            <Box borderBottomWidth='1px'>
              <SelectInput {...select.inputProps()} />
            </Box>
            <ScrollAreaAutosize maxH='xs' overflow='auto'>
              <chakra.ul p={1.5} data-pw='menu'>
                {isLoading && P.isEmpty(select.filteredOptions) && Components.Loading()}
                {!isLoading && P.isEmpty(select.filteredOptions) && Components.NoOptions()}

                {select.filteredOptions.map((option) => (
                  <SelectItem key={option.value} {...select.optionProps(option)}>
                    {Components.Option({ option })}
                  </SelectItem>
                ))}
              </chakra.ul>
            </ScrollAreaAutosize>
            {Components.Footer()}
          </SelectContainer>
        )}
      >
        <Box tabIndex={-1}>
          <SelectButton
            {...select.buttonProps()}
            variant={variant}
            size={size}
            showArrow={showArrow}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            <Wrap spacing={1} w='full'>
              {P.isEmpty(select.selectedValues)
                ? Components.Placeholder({ placeholder })
                : select.selectedValues.map((option, index) => (
                    <WrapItem key={option.value} data-pw={`selected-${option.value}`}>
                      {Components.Value({
                        selectedValues: select.selectedValues,
                        option,
                        onRemove: () => select.selectOption(option),
                        index,
                      })}
                    </WrapItem>
                  ))}
            </Wrap>
          </SelectButton>
        </Box>
      </FloatingPopover>
    </chakra.div>
  );
};
