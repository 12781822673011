import { InputGroup, InputRightAddon } from '@chakra-ui/react';
import {
  type ReportTemplateChoiceVariable,
  type ReportTemplateStaticVariable,
  type ReportTemplateVariable,
  type ReportTemplateVariableChoice,
  type ReportTemplateWallMotionVariable,
  VariableControlType,
} from '@piccolohealth/echo-common';
import type { SelectOption } from '@piccolohealth/ui';
import { useReport } from '../../../context/ReportContext';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedMultiSelect } from '../../forms/hookform/HookedMultiSelect';
import { HookedSingleDatepicker } from '../../forms/hookform/HookedSingleDatepicker';
import { HookedWallMotionControl } from '../../forms/hookform/HookedWallMotionControl';
import React from 'react';

const StaticVariableControl = (props: { variable: ReportTemplateStaticVariable }) => {
  const { variable } = props;
  const name = `variables.${variable.id}.value`;
  const report = useReport();

  const isDisabled = report.isDisabled;

  switch (variable.controlType) {
    case VariableControlType.Input: {
      return (
        <InputGroup size='sm'>
          <HookedInput
            name={name}
            borderRightRadius={variable?.units ? 0 : 'md'}
            isDisabled={isDisabled}
          />
          {variable.units && (
            <InputRightAddon borderRightRadius='md'>{variable.units}</InputRightAddon>
          )}
        </InputGroup>
      );
    }

    case VariableControlType.Datepicker:
      return <HookedSingleDatepicker name={name} isDisabled={isDisabled} />;

    default:
      return null;
  }
};

const ChoiceVariableControl = (props: { variable: ReportTemplateChoiceVariable }) => {
  const { variable } = props;
  const { isDisabled } = useReport();
  const name = `variables.${variable.id}.value`;

  const options: SelectOption<ReportTemplateVariableChoice>[] = variable.choices.map(
    (choice: ReportTemplateVariableChoice) => ({
      label: choice.label,
      value: choice.value,
      raw: choice,
    }),
  );

  switch (variable.controlType) {
    case VariableControlType.Select: {
      return (
        <HookedMultiSelect
          options={options}
          mode="single"
          name={name}
          isDisabled={isDisabled}
          placeholder='Select an option'
          data-pw={`template-${name}`}
        />
      );
    }

    case VariableControlType.Multiselect: {
      return (
        <HookedMultiSelect
          options={options}
          name={name}
          isDisabled={isDisabled}
          data-pw={`template-${name}`}
          placeholder='Select an option'
        />
      );
    }

    default:
      return null;
  }
};

const WallMotionVariableControl = (props: { variable: ReportTemplateWallMotionVariable }) => {
  const { variable } = props;
  const { isDisabled } = useReport();

  return (
    <HookedWallMotionControl name={`variables.${variable.id}.value`} isDisabled={isDisabled} />
  );
};

export const VariableControl = (props: { variable: ReportTemplateVariable }) => {
  const { variable } = props;

  switch (variable.__typename) {
    case 'ReportTemplateStaticVariable': {
      return <StaticVariableControl variable={variable as ReportTemplateStaticVariable} />;
    }
    case 'ReportTemplateChoiceVariable': {
      return <ChoiceVariableControl variable={variable as ReportTemplateChoiceVariable} />;
    }
    case 'ReportTemplateWallMotionVariable':
      return <WallMotionVariableControl variable={variable as ReportTemplateWallMotionVariable} />;
    default:
      return null;
  }
};
