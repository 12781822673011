import { HStack, type StackProps, Text } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import {
  FeatureFlag,
  getRangeResult,
  getRelevantRangesForCriteria,
  getReportTemplateVariableById,
  getReportVariableValueAsNumber,
  getReportVariableValueAsString,
  isReportStaticVariable,
  isReportTemplateStaticVariable,
  type MeasurementRange,
  PERMISSIONS,
  type ReportVariable,
} from '@piccolohealth/echo-common';
import { FloatingPopover } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import { MeasurementPopover } from '../../../../features/measurements/MeasurementPopover';
import { MeasurementRangeDot } from '../../../../features/measurements/MeasurementRangeDot';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';
import { usePermission } from '../../../../hooks/usePermission';
import { type BaseNodeProps, cleanProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { type NodeSpacingProps, NodeSpacingSettings } from '../../common/nodes/NodeSpacing';
import {
  type NodeTypographyProps,
  NodeTypographySettings,
} from '../../common/nodes/NodeTypography';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

interface MeasurementRangeGroupDotProps {
  variableId: string;
}

const MeasurementRangeResultDot = (props: MeasurementRangeGroupDotProps) => {
  const { variableId } = props;

  const { reportTemplate, hasFeature } = useReport();

  const variablesObj = useWatch({ name: 'variables' }) as Record<string, ReportVariable>;
  const variables = Object.values(variablesObj);
  const variable = variablesObj[variableId];

  const reportTemplateVariable = getReportTemplateVariableById(
    reportTemplate.variables,
    variableId,
  );

  if (!hasFeature(FeatureFlag.NormalRanges)) {
    return null;
  }

  if (!reportTemplateVariable) {
    return null;
  }

  if (P.isNil(variable)) {
    return null;
  }

  if (
    !isReportStaticVariable(variable) ||
    !isReportTemplateStaticVariable(reportTemplateVariable)
  ) {
    return null;
  }

  const rangeResult = getRangeResult(variableId, reportTemplateVariable, variables);

  if (P.isNil(rangeResult)) {
    return null;
  }

  const criteria = {
    age: getReportVariableValueAsNumber(variables, 'age') ?? null,
    sex: getReportVariableValueAsString(variables, 'sex') ?? null,
    measurement: getReportVariableValueAsNumber(variables, variableId),
  };

  const applicableRangesForSexAndAge: MeasurementRange[] = getRelevantRangesForCriteria(
    rangeResult.rangeGroup.ranges,
    criteria,
  );

  const applicableRange = applicableRangesForSexAndAge.find(
    (range) => !P.isNil(rangeResult.range) && P.isEqual(range, rangeResult.range),
  );

  if (P.isEmpty(applicableRangesForSexAndAge)) {
    return null;
  }

  return <MeasurementRangeDot range={applicableRange ?? null} />;
};

interface MeasurementTextProps extends StackProps {
  variableId: string;
  prefix?: string;
  suffix?: string;
  isDisabled?: boolean;
}

const MeasurementText = (props: MeasurementTextProps) => {
  const { variableId, prefix, suffix, isDisabled, ...rest } = props;

  const compiledVariable = useCompiledTemplate(`{{withPlaceholder ${variableId} '-'}}`);
  const hasPermission = usePermission(PERMISSIONS.reportsRead).value;

  return (
    <FloatingPopover
      enabled={hasPermission}
      render={() => <MeasurementPopover variableId={variableId} isDisabled={isDisabled} />}
      trigger='hover'
    >
      <HStack {...rest} spacing={0}>
        {prefix && <Text>{prefix}</Text>}
        <HStack spacing={1}>
          {compiledVariable.compiledTemplate !== '-' && (
            <MeasurementRangeResultDot variableId={variableId} />
          )}
          <Text>{compiledVariable.compiledTemplate}</Text>
        </HStack>
        {suffix && <Text>{suffix}</Text>}
      </HStack>
    </FloatingPopover>
  );
};

export interface Props extends BaseNodeProps, NodeSpacingProps, NodeTypographyProps {
  variableId: string;
  secondaryVariableId: string | null;
}

export const Measurement = (props: Props) => {
  const { variableId, secondaryVariableId, bold, italic, underline, ssr, ...rest } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const style = P.pickBy(
    {
      fontWeight: bold ? 'bold' : undefined,
      fontStyle: italic ? 'italic' : undefined,
      textDecoration: underline ? 'underline' : undefined,
      textDecorationThickness: '2px',
      textUnderlineOffset: '2px',
      cursor: 'pointer',
      borderColor: 'transparent',
      borderWidth: '1px 2px',
      _hover: {
        bg: 'gray.100',
        borderColor: 'gray.100',
      },
      ...rest,
    },
    (value) => !P.isNil(value),
  );

  return (
    <HStack
      ref={(ref) => connect(drag(ref))}
      spacing={0}
      lineHeight='normal'
      flexShrink={0}
      align='center'
    >
      <MeasurementText variableId={variableId} {...cleanProps(style)} />
      {secondaryVariableId && (
        <MeasurementText
          variableId={secondaryVariableId}
          prefix='('
          suffix=')'
          isDisabled
          {...cleanProps(style)}
        />
      )}
    </HStack>
  );
};

const MeasurementSettings = () => {
  const {
    actions: { setProp },
    variableId,
    secondaryVariableId,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    secondaryVariableId: node.data.props.secondaryVariableId,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='Measurement'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) =>
              setProp((props: Props) => {
                props.variableId = value;
              })
            }
          />
        </FormItem>
        <FormItem id='secondaryVariableId' label='Secondary variable ID'>
          <VariableChooser
            variableId={secondaryVariableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) =>
              setProp((props: Props) => {
                props.secondaryVariableId = value;
              })
            }
          />
        </FormItem>
      </SettingsItem>
      <SettingsItem title='Spacing'>
        <NodeSpacingSettings />
      </SettingsItem>
      <SettingsItem title='Typography'>
        <NodeTypographySettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

Measurement.defaultProps = {
  variableId: 'lvEddMeasurement',
  secondaryVariableId: null,
  ...NodeSpacingSettings.defaultProps,
  ...NodeTypographySettings.defaultProps,
};

Measurement.craft = {
  name: 'Measurement',
  props: Measurement.defaultProps,
  related: {
    settings: MeasurementSettings,
  },
};
